import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

const useUserProfile = () => {
  const toast = useToast();

  const getUserProfile = async () => {
    try {
      const data = await store.dispatch('user/getUserProfile');
      return data;
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the user profile',
          icon: 'XCircleIcon',
        },
      });
      return undefined;
    }
  };

  const editUserProfile = async (user) => {
    try {
      await store.dispatch('user/editUserProfile', user);
      store.commit('authentication/storeUserInfo', { ...user });
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the user profile',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    getUserProfile,
    editUserProfile,
  };
};

export default useUserProfile;
