<template>
  <b-card>
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        User not found
      </div>
    </b-alert>

    <div v-if="userData">
      <div class="d-flex">
        <feather-icon
          icon="UserIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Personal Information
        </h4>
      </div>

      <validation-observer ref="formRef">
        <b-form
          class="mt-1"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="First Name"
                label-for="first-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    id="first-name"
                    v-model="userData.firstName"
                    :state="errors.length > 0 ? false : null"
                    name="first-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Last Name"
                label-for="last-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-input
                    id="last-name"
                    v-model="userData.lastName"
                    :state="errors.length > 0 ? false : null"
                    name="last-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    :state="errors.length > 0 ? false : null"
                    name="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="saveChanges"
              >
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>

  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api';
import {
  BTab, BTabs, BCard, BAlert, BLink, BForm, BRow, BCol, BButton, BFormGroup, BFormInput
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import useUserProfile from '../composables/useUserProfile';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    required
  },
  setup() {
    const toast = useToast();
    const { editUserProfile, getUserProfile } = useUserProfile();

    const formRef = ref(null);
    const userData = ref(null);

    const saveChanges = () => {
      formRef.value.validate().then(valid => {
        if (!valid) return;

        editUserProfile(userData.value).then(_ => {
          toast.success({
            component: ToastificationContent,
            props: {
              title: 'Profile has been saved successfully!',
              icon: 'CheckIcon',
            },
          });
        });

      });
    };

    getUserProfile().then(user => {
      userData.value = user;
    });

    return {
      userData,
      formRef,
      saveChanges
    };
  },
};
</script>

<style>

</style>
